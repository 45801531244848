import React, { useState, useEffect, useContext, Fragment, useCallback } from 'react';
import {
  GENDER_MAPPING,
  PAYMENT_PLAN_MAPPING,
  BOOL_MAPPING,
  ACQUISITION_CHANNEL_MAPPING,
  PAYMENT_CHANNEL_MAPPING,
  PRODUCT_TYPE,
  ACQUISITION_REASON_MAPPING,
} from '../../../utils/mappings';
import axios from 'axios';
import { endpoints } from '../../../utils/URL';
import { STATES_LGA } from '../../../utils/states_lga';
import { insurancePlans } from '../../../utils/insurancePlans';
import { addToast } from '../../../utils/toastNotifications';
import { isFormValidated } from '../../../utils/formUtils';
import {
  initialNextOfKinData,
  initialSubscriptionPostData,
  ISubscriptionData,
} from '../../../interface/SubscriptionInterface';
import { initialPartnerDTO } from '../../../interface/PartnerInterface';
import { initialBeneficiaryList } from '../../../interface/BeneficiaryInterface';
import { AppContext } from '../../../context/AppContext';
import './styles.css';
import AddBeneficiaryModal from './AddBeneficiaryModal';
import AddNextOfKinModal from './AddNextOfKinModal';
import ButtonLoader from '../../common/ButtonLoader';
import { formatInputDate } from '../../../utils/dateTimeUtils';

const AddSubscriptionForm = () => {
  const { appState } = useContext(AppContext);
  const [open, setOpen] = useState(false);
  // const [payOpen, setPayOpen] = useState(false);
  const [partnerDisabled, setPartnerDisabled] = useState(true);
  const leadsData = appState.oneClickLeadsData;
  const [nextOfKinOpen, setNextOfKinOpen] = useState(false);

  // const initialState = initialSubscriptionPostData;
  // initialState.firstName = leadsData != null ? leadsData.firstName : '';
  // initialState.lastName = leadsData != null ? leadsData.lastName : '';
  // initialState.phone = leadsData != null ? leadsData.phone : '';
  // initialState.email = leadsData != null ? leadsData.email : '';
  // initialState.location = leadsData != null ? leadsData.location : '';
  // initialState.product = productName;
  // initialState.paymentPlan = paymentPlan;
  // // initialState.beneficiaryList = [];
  // initialState.nextOfKinDTO = initialNextOfKinData;

  const loadDefaultFromState = useCallback(() => {
    if (leadsData !== null && typeof leadsData !== 'undefined') {
      let productName = 'Malaria';
      const leadsProductType = leadsData.productType;
      switch (leadsProductType) {
        case 'Free':
          productName = 'Malaria';
          break;
        case 0:
          productName = 'Malaria';
          break;
        default:
          productName = leadsProductType;
          break;
      }

      let paymentPlan = 'Monthly';
      const leadsPaymentPlan = leadsData.paymentPlan;
      switch (leadsPaymentPlan) {
        case 12:
          paymentPlan = 'Annually';
          break;
        case 6:
          paymentPlan = 'Biannually';
          break;
        case 3:
          paymentPlan = 'Quarterly';
          break;
        default:
          paymentPlan = 'Monthly';
          break;
      }

      const initialState = initialSubscriptionPostData;
      initialState.firstName = leadsData != null ? leadsData.firstName : '';
      initialState.lastName = leadsData != null ? leadsData.lastName : '';
      initialState.phone = leadsData != null ? leadsData.phone : '';
      initialState.email = leadsData != null ? leadsData.email : '';
      initialState.location = leadsData != null ? leadsData.location : '';
      initialState.product = productName;
      initialState.paymentPlan = paymentPlan;
      // initialState.beneficiaryList = [];
      initialState.nextOfKinDTO = initialNextOfKinData;

      setLocalState(initialState);
      setAmount(leadsData.amount);
    }
  }, [leadsData]);

  useEffect(() => {
    loadDefaultFromState();
  }, [loadDefaultFromState]);

  const [beneficiaryData, setBeneficiaryData] = useState(initialBeneficiaryList);

  const [localState, setLocalState] = useState<ISubscriptionData>(initialSubscriptionPostData);
  const [isLoading, setLoading] = useState(false);
  const [partnerData, setPartnerData] = useState([initialPartnerDTO]);
  const [amount, setAmount] = useState(initialSubscriptionPostData.amount);
  const [nextOfKinData, setNextOfKinData] = useState(initialNextOfKinData);

  const handleChange = (inputName: string) => (event: any) => {
    if (inputName === 'isBeneficiary') {
      const result = checkForEmptyBeneficiaries(event.target.value);
      if (!result) {
        setLocalState({
          ...localState,
          [inputName]: true,
        });
        return;
      }
      setLocalState({
        ...localState,
        [inputName]: !localState.isBeneficiary,
      });
    } else if (inputName === 'acquisitionChannel' && event.target.value === 'Partner') {
      setPartnerDisabled(false);
      setLocalState({
        ...localState,
        [inputName]: event.target.value,
        partnerCode: partnerData.length > 0 ? partnerData[0].partnerCode : '',
      });
    } else if (inputName === 'partnerCode') {
      setPartnerDisabled(false);
      setLocalState({
        ...localState,
        [inputName]: event.target.value,
      });
    } else {
      setPartnerDisabled(true);
      setLocalState({
        ...localState,
        [inputName]: event.target.value,
      });
    }
  };

  const handleBeneficiaryChange = (inputName: string) => (event: any) => {
    setBeneficiaryData({
      ...beneficiaryData,
      [inputName]: event.target.value,
    });
  };

  const handleNextOfKinDataChange =
    (inputName: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setNextOfKinData({
        ...nextOfKinData,
        [inputName]: event.target.value,
      });
    };

  const checkForEmptyBeneficiaries = (isBeneficiary: string) => {
    if (isBeneficiary === 'false' && localState.beneficiaryList.length === 0) {
      addToast("You must add atleast one beneficiary or set IsBeneficiary as 'Yes'", 'warning');
      return false;
    }
    return true;
  };

  const nextOfKinClose = () => {
    setNextOfKinOpen(false);
  };

  const handleNextOfKinOpen = () => {
    setNextOfKinOpen(true);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const resetBeneficiary = () => {
    setBeneficiaryData(initialBeneficiaryList);
  };

  const addNextOfKinToPlan = () => {
    setLocalState({
      ...localState,
      nextOfKinDTO: nextOfKinData,
    });

    addToast('Next of Kin Data Added!!', 'success');
    nextOfKinClose();
  };

  const addBeneficiary = () => {
    let savedBeneficiaries = localState.beneficiaryList;

    //total beneficiaries + 1 (if isBeneficiary is true)
    if (savedBeneficiaries.length === 4 && localState.isBeneficiary) {
      addToast('Maximum of 5 beneficiaries is allowed', 'info');

      return;
    }

    savedBeneficiaries.push(beneficiaryData);

    setLocalState({
      ...localState,
      beneficiaryList: savedBeneficiaries,
    });

    addToast('Beneficiary added successfully', 'success');
    resetBeneficiary();
  };

  const clearBeneficiaries = () => {
    localState.beneficiaryList.length = 0;
    setLocalState({
      ...localState,
      beneficiaryList: [],
    });
    addToast('Beneficiary list cleared', 'info');
  };

  const submit = async (e: React.FormEvent) => {
    if (!isFormValidated('subscription-form')) {
      return;
    }
    e.preventDefault();

    if (!partnerDisabled && localState.partnerCode === '') {
      addToast('Please select a valid partner from the list', 'warning');
      return;
    }

    if (localState.product === 'Cashplus' && localState.nextOfKinDTO?.firstName === '') {
      addToast('Add Next Of Kin Information in the Modal Box', 'warning');
      return;
    }

    if (localState.product === 'Malaria') {
      localState.nextOfKinDTO = undefined;
    }

    setLoading(true);

    const payload = localState;

    payload.amount = amount;

    try {
      const response = await axios.post(endpoints.InsuranceSubscriptions.mainUrl, payload);

      if (response.status === 200) {
        addToast('subscription saved successfully', 'success');
      }
      handleClose();
      clearForm();
    } catch (error: any) {
      if (error.response) {
        addToast('There was an error sending your request', 'error');
      }
    } finally {
      setLoading(false);
    }
  };

  const clearForm = () => {
    localState.beneficiaryList.length = 0;
    setLocalState(initialSubscriptionPostData);
  };

  useEffect(() => {
    const calculatePayment = () => {
      const paymentPlan = localState.paymentPlan;
      const initialBeneficiary = localState.isBeneficiary === true ? 1 : 0;
      const totalBeneficiaries = initialBeneficiary + localState.beneficiaryList.length;
      const product = localState.product;

      if (totalBeneficiaries < 1) {
        addToast("You must add atleast one beneficiary or set IsBeneficiary as 'Yes'", 'warning');
        return;
      }

      const selectedPayment = insurancePlans.filter(
        (x) =>
          x.numberOfPersons === totalBeneficiaries &&
          x.planType === paymentPlan &&
          x.product === product
      );

      if (selectedPayment === null || selectedPayment.length === 0) {
        addToast('That Plan combination is invalid', 'error');
        return;
      }

      const price = selectedPayment[0].price;
      setAmount(price);
    };
    calculatePayment();
  }, [
    localState.paymentPlan,
    localState.isBeneficiary,
    localState.beneficiaryList.length,
    localState.product,
    amount,
  ]);

  useEffect(() => {
    const getPartners = async () => {
      try {
        const response = await axios.get(endpoints.InsurancePartners.getPartnerList);
        setPartnerData(response.data);
      } catch (error: any) {
        addToast('Error getting list of Partners', 'error');
      }
    };
    getPartners();
  }, []);

  const statesType = STATES_LGA;
  const statesMap = statesType.map((states) => {
    return {
      key: states.id,
      text: states.state,
      value: states.state,
    };
  });

  const state_filter = STATES_LGA.filter((selected_state) => {
    return selected_state.state === localState.stateOfResidence;
  });

  let lgas = state_filter[0].locals.map((lga) => {
    return {
      key: lga.id,
      text: lga.name,
      value: lga.name,
    };
  });

  let beneficiaryModal = (
    <AddBeneficiaryModal
      open={open}
      close={handleClose}
      handleChange={handleBeneficiaryChange}
      modalData={beneficiaryData}
      addToPlan={addBeneficiary}
    />
  );

  let nextOfKinModal = (
    <AddNextOfKinModal
      open={nextOfKinOpen}
      close={nextOfKinClose}
      handleChange={handleNextOfKinDataChange}
      modalData={nextOfKinData}
      addToPlan={addNextOfKinToPlan}
    />
  );

  return (
    <Fragment>
      <div className='container'>
        <h4 style={{ textAlign: 'center', color: '#000000' }}>Add Subscriptions</h4>
        <br />
        <form id='subscription-form'>
          <div className='row'>
            <div className='col-md-3'>
              <label className='form-label'>First Name</label>
              <input
                value={localState.firstName}
                required
                className='form-control'
                type='text'
                onChange={handleChange('firstName')}
              />
            </div>
            <div className='col-md-3'>
              <label className='form-label'>Last Name</label>
              <input
                value={localState.lastName}
                required
                className='form-control '
                type='text'
                onChange={handleChange('lastName')}
              />
            </div>
            <div className='col-md-3'>
              <label className='form-label'>Phone Number</label>
              <input
                value={localState.phone}
                maxLength={13}
                pattern='[0]{1}[7-9]{1}[0-2]{1}[0-9]{8}|[2]{1}[3]{1}[4]{1}[0-9]{10}'
                required
                className='form-control '
                type='tel'
                onChange={handleChange('phone')}
              />
            </div>
            <div className='col-md-3'>
              <label className='form-label'>Email address</label>
              <input
                value={localState.email}
                className='form-control '
                type='email'
                placeholder='name@example.com'
                onChange={handleChange('email')}
              />
            </div>
          </div>
          <br />
          <div className='row'>
            <div className='col-md-3'>
              <label className='form-label'>Address</label>
              <input
                value={localState.location}
                required
                className='form-control '
                type='text'
                onChange={handleChange('location')}
              />
            </div>

            <div className='col-md-3'>
              <label className='form-label'>State Of Residence</label>
              <select
                required
                onChange={handleChange('stateOfResidence')}
                value={localState.stateOfResidence}
                className='custom-select'
              >
                {statesMap.map((option) => (
                  <option key={option.key} value={option.value.toString()}>
                    {option.text}
                  </option>
                ))}
              </select>
            </div>

            <div className='col-md-3'>
              <label className='form-label'>LGA</label>
              <select
                onChange={handleChange('lga')}
                value={localState.lga}
                className='custom-select'
              >
                <option value=''></option>
                {lgas.map((option) => (
                  <option key={option.key} value={option.value.toString()}>
                    {option.text}
                  </option>
                ))}
              </select>
            </div>

            <div className='col-md-3'>
              <label className='form-label'>Town/Area</label>
              <input
                value={localState.area}
                className='form-control '
                type='text'
                onChange={handleChange('area')}
              />
            </div>
          </div>
          <br />
          <br />
          <div className='row'>
            <div className='col-md-3'>
              <label className='form-label'>Street Name</label>
              <input
                value={localState.streetName}
                className='form-control '
                type='text'
                onChange={handleChange('streetName')}
              />
            </div>

            <div className='col-md-3'>
              <label className='form-label'>Acquisition Channel</label>
              <select
                required
                onChange={handleChange('acquisitionChannel')}
                value={localState.acquisitionChannel}
                className='custom-select'
              >
                {ACQUISITION_CHANNEL_MAPPING.map((option) => (
                  <option key={option.key} value={option.value.toString()}>
                    {option.text}
                  </option>
                ))}
              </select>
            </div>

            <div className='col-md-3'>
              <label className='form-label'>Acquisition Reason</label>
              <select
                required
                onChange={handleChange('acquisitionReason')}
                value={localState.acquisitionReason}
                className='custom-select'
              >
                {ACQUISITION_REASON_MAPPING.map((option) => (
                  <option key={option.key} value={option.value}>
                    {option.text}
                  </option>
                ))}
              </select>
            </div>

            <div className='col-md-3'>
              <label className='form-label'>Partner Code</label>
              <select
                required={!partnerDisabled}
                disabled={partnerDisabled}
                onChange={handleChange('partnerCode')}
                value={localState.partnerCode}
                className='custom-select'
              >
                {partnerData.map((option) => (
                  <option key={option.partnerCode} value={option.partnerCode}>
                    {option.partnerName}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <br />
          <br />
          <div className='row'>
            <div className='col-md-3'>
              <label className='form-label'>Is Beneficiary</label>
              <select
                required
                onChange={handleChange('isBeneficiary')}
                className='form-control'
                value={localState.isBeneficiary.toString()}
              >
                {BOOL_MAPPING.map((option) => (
                  <option key={option.key} value={option.value.toString()}>
                    {option.text}
                  </option>
                ))}
              </select>
            </div>

            <div className='col-md-3'>
              <label className='form-label'>Product</label>
              <select
                value={localState.product}
                required
                onChange={handleChange('product')}
                className='custom-select'
              >
                {PRODUCT_TYPE.map((option) => (
                  <option key={option.key} value={option.value}>
                    {option.text}
                  </option>
                ))}
              </select>
            </div>

            <div className='col-md-3'>
              <label className='form-label'>Amount</label>
              <input
                value={amount}
                disabled
                required
                className='form-control '
                type='number'
                min={0}
                step={0.01}
              />
            </div>

            <div className='col-md-3'>
              <label className='form-label'>Payment Plan</label>
              <select
                required
                value={localState.paymentPlan}
                onChange={handleChange('paymentPlan')}
                className='custom-select'
                disabled={localState.product.toLowerCase().includes('diabetes')}
              >
                {PAYMENT_PLAN_MAPPING.map((option) => (
                  <option key={option.key} value={option.value}>
                    {option.text}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <br />
          <br />
          <div className='row'>
            <div className='col-md-3'>
              <label className='form-label'>Gender</label>
              <select
                value={localState.gender}
                onChange={handleChange('gender')}
                className='custom-select'
              >
                {GENDER_MAPPING.map((option) => (
                  <option key={option.key} value={option.value}>
                    {option.text}
                  </option>
                ))}
              </select>
            </div>

            <div className='col-md-3'>
              <label className='form-label'>Date of Birth</label>
              <input
                value={formatInputDate(localState.dateOfBirth)}
                required
                className='form-control '
                type='date'
                onChange={handleChange('dateOfBirth')}
              />
            </div>

            <div className='col-md-3'>
              <label className='form-label'>Payment Channel</label>
              <select
                value={localState.paymentChannel}
                required
                onChange={handleChange('paymentChannel')}
                className='custom-select'
              >
                <option value=''></option>

                {PAYMENT_CHANNEL_MAPPING.map((option) => (
                  <option key={option.key} value={option.value}>
                    {option.text}
                  </option>
                ))}
              </select>
            </div>

            <div className='col-md-3'>
              <label className='form-label'>Payment Date</label>
              <input
                value={formatInputDate(localState.paymentDate)}
                required
                className='form-control '
                type='date'
                onChange={handleChange('paymentDate')}
              />
            </div>
          </div>

          <div className='row'>
            <div className='col-md-3 mt-3'>
              <h5 className='form-label'>Additional Beneficiaries</h5>
              <div className='row'>
                <div className='col-md-4'>
                  <i className='bi bi-people people-icon'></i>
                </div>
                <div className='col-md-4'>
                  <strong>{localState.beneficiaryList.length}</strong>
                </div>
                <div className='col-md-4'>
                  <i onClick={clearBeneficiaries} className='bi bi-x close-icon'></i>
                </div>
              </div>
            </div>
          </div>

          <div className='row justify-content-center' style={{ marginTop: '60px' }}>
            <div className='col-md-3'>
              <button onClick={handleClickOpen} type='button' className='btn btn-block btn-info'>
                Add Beneficiary
              </button>
              {beneficiaryModal}
            </div>

            {localState.product !== 'Malaria' && localState.product !== 'Pharma' ? (
              <div className='col-md-3'>
                <button
                  type='button'
                  onClick={handleNextOfKinOpen}
                  className='btn btn-block btn-info'
                >
                  Add Next of Kin
                  {localState.nextOfKinDTO?.firstName !== '' ? (
                    <span className='bi bi-check-circle'></span>
                  ) : (
                    ''
                  )}
                </button>
                {nextOfKinModal}
              </div>
            ) : (
              ''
            )}
            <div className='col-md-3'>
              <button className='btn btn-block  btn-primary' type='submit' onClick={submit}>
                SUBMIT
                {isLoading ? <ButtonLoader /> : ''}
              </button>
            </div>
            <div className='col-md-3'>
              <button className='btn btn-block btn-danger' type='reset' onClick={clearForm}>
                CLEAR
              </button>
            </div>
          </div>
        </form>
      </div>
    </Fragment>
  );
};

export default AddSubscriptionForm;
